.product {
    width: 300px;
    height: 510px;
    border: 1px solid #95A0A7;
    border-radius: 8px;
    position: relative;
}

.product-info {
    margin: 0 14px;
}

.product-info p {
    margin-bottom: 0;
    margin-top: 5px;
}

.product img {
    width: 286px;
    height: 286px;
    border-radius: 8px;
    margin: 7px;
}

.product-name {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    margin-top: 7px;
}

.btn-cart {
    width: 100%;
    background: #FFE0B3;
    border: none;
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 5px; */
}
.btn-text{
    margin-right: 5px;
}
.btn-cart:hover{
    background-color: orange;
}